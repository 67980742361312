import React from 'react';
import {gsap, Linear,Expo, Power4} from 'gsap';
const $=window.jQuery;
class Intro extends React.Component {
    constructor(props){
        super(props);
        this.frame = 0;
        this.state={
            frame:this.frame
        }
    }

    componentDidMount(){
        $('.intro-container').each((i,v)=>{
            gsap.set(v,
            {clip:`rect(${0}px,${window.innerWidth}px,${window.innerHeight}px,${window.innerWidth/5*i}px)`});

        })
        const tl = new gsap.timeline();
        tl.fromTo(this,{frame:0},{frame:77,ease:Linear.easeNone, duration:2.0,onUpdate:()=>{
            const numStr=Math.ceil(this.frame).toString();
            this.setState({frame:numStr.padStart(2,0)})
        }});
        $('.intro-container').each((i,v)=>{
  
            tl.to(v,
            {
                clip:`rect(${window.innerHeight}px,${window.innerWidth}px,${window.innerHeight}px,${window.innerWidth/5*i}px)`,
                ease: Expo.easeInOut,
                duration:1+(0.2*i)
             },2);
        })
        tl.to('.intro',
        {
            y:-500,
            ease: Expo.easeInOut,
            duration:1.5,
            onStart:()=>{
                this.props.onTransitionStart()
            }
         },2);
         const introScalerEnd=1200;
         const introScaler = gsap.fromTo('.intro-scale',{scale:0},{scale:1,duration:introScalerEnd});
         
         if(window.innerWidth<=introScalerEnd){
            introScaler.pause(window.innerWidth);
         }else{
            introScaler.pause(introScalerEnd);
         }
    }
    render(){
        return (
            <div className="intro-parent">
                <div className="intro-container intro-container-main">
                    <div className="intro-scale"><div className="intro" id="intro-animation" data-frame={this.state.frame}>
                    </div></div>
                </div>
                <div className="intro-container">
                    <div className="intro-scale"><div className="intro" data-frame={this.state.frame}>
                    </div></div>
                </div>
                <div className="intro-container">
                    <div className="intro-scale"><div className="intro" data-frame={this.state.frame}>
                    </div></div>
                </div>
                <div className="intro-container">
                    <div className="intro-scale"><div className="intro" data-frame={this.state.frame}>
                    </div></div>
                </div>
                <div className="intro-container">
                    <div className="intro-scale"><div className="intro" data-frame={this.state.frame}>
                    </div></div>
                </div>
            </div>
        );
    }
}

export default Intro;
