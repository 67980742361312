const $=window.jQuery;
export default {
    // isTouch: ()=> {
    //     const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
    //     const mq = function(query) {
    //       return window.matchMedia(query).matches;
    //     };
      
    //     if (
    //       "ontouchstart" in window ||
    //       (window.DocumentTouch && document instanceof DocumentTouch)
    //     ) {
    //       return true;
    //     }
    //     const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
    //       ""
    //     );
    //     return mq(query);
    // },
    isMobile:()=>{
      return (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    },
    spanify:(selector)=>{
      const $els=$(selector);
      $els.each((i,v)=>{
        const $el = $(v);
        const textArr = $el.html().split(' ');
        $el.html('');
        $(textArr).each((j,word)=>{
          const rx=/(<br>)/ig;
          const hasBr=word.match(rx);
          let $word=$(`<span class="word"><span class="word-inner">${word.replace(rx,"")}</span></span>`);
          $el.append($word);
          $el.append(' ');
          if(hasBr){
            $el.append($('<br>'))
          }
        })
        // const spanArr = textArr.map(word=>`<span class="word"><span class="word-inner">${word}</span></span>`);
        // $el.html(spanArr.join(' '))
      });
      return $els;
    }
}