import React from 'react';
import Intro from './Intro';
import LocomotiveScroll from "locomotive-scroll";
import Utils from "./Utils";
import inView from "in-view";
import './scss/styles.scss';
import {gsap, Linear,Expo, Power3} from 'gsap';
const $=window.jQuery;
let scrollInicatorOrigin;
let m={
  x:null,y:null
};
class App extends React.Component {
  updateScrollProgress(){
    const progress =this.scroll.scroll.instance.scroll.y/this.scroll.scroll.instance.limit;
    gsap.set('.scroll-progress .bar',{height:progress*window.innerHeight});
    //console.log(this.scroll.scroll.instance.limit/this.scroll.scroll.instance.scroll.y)
  }
  updateScrollIndicatorOrigin(){
    const scrollIndicator = document.querySelector('.scroll-indicator .origin');
    const scrollIndicatorBounds = scrollIndicator.getBoundingClientRect();
    scrollInicatorOrigin = {
      x: scrollIndicatorBounds.left,
      y: scrollIndicatorBounds.top
    }
  }
  handleResize(){
    this.updateScrollProgress();
 
  }
  handleScroll(){
    this.updateScrollProgress();
    
  }
  componentDidMount() {
    Utils.spanify('.hero h1, .mission h2, .contact h3');
    $(`.hero h1 .word-inner, .mission h2 .word-inner, .contact h3 .word-inner`).each((i,v)=>{
      gsap.set(v,{y:100});
    });
    this.missionTl = new gsap.timeline();
    const missionTlDelay = Utils.isMobile()?0.3:0.4;
    $(`.mission h2 .word-inner`).each((i,v)=>{
      this.missionTl.fromTo(v,{y:100},{y:0, ease:Expo.easeOut, duration: 0.2+i*0.35}, missionTlDelay+ (i*0.015));
    });
    this.missionTl.pause(0);
    this.contactTl=new gsap.timeline();
    const contactTlDelay = Utils.isMobile()?0.3:0.75;
    $(`.contact h3 .word-inner`).each((i,v)=>{
      this.contactTl.fromTo(v,{y:100},{y:0, ease:Expo.easeOut, duration: 0.2+i*0.35}, contactTlDelay+ (i*0.015));
    });
    this.contactTl.pause(0);

    if(!Utils.isMobile()){
      setTimeout(() => {
        this.scroll = new LocomotiveScroll({
          el: document.querySelector("#js-scroll"),
          smooth: true
        });
        this.scroll.on('call',func=>{

          if(func=='mission-h2'){
            this.missionTl.play(0);
          }
          if(func=='contact-h3'){
            this.contactTl.play(0);
          }
        })
        this.scroll.on('scroll',this.handleScroll.bind(this));
        $(window).on('resize',this.handleResize.bind(this));
        this.updateScrollIndicatorOrigin();
        const scrollIndicatorTween = new gsap.timeline({ repeat:-1})
        scrollIndicatorTween.fromTo('.scroll-indicator .icon',{y:0},{duration:0.5,y:80,ease:Expo.easeIn});
        scrollIndicatorTween.set('.scroll-indicator .icon',{y:-80});
        scrollIndicatorTween.to('.scroll-indicator .icon',{duration:1,y:0,ease:Expo.easeOut});
  
        scrollIndicatorTween.pause(0);

        $('.hero').on('mousemove mouseleave',(e)=>{
          //console.log(e.type)
          m.x=e.pageX;
          m.y=e.pageY;
          this.updateScrollIndicatorOrigin();
          let dist = Math.hypot(m.x-scrollInicatorOrigin.x,m.y-scrollInicatorOrigin.y);
          let dest;
          if (dist>185 || e.type=='mouseleave'){
            dest= {
              x:0,
              y:0,
              // height:55,
              // borderColor:'rgba(255,255,255,0)'
             }
             scrollIndicatorTween.pause(0);
             $('body').removeClass('scroll-indicator-hover')
          }else{
 
            dest= {
              x:m.x-scrollInicatorOrigin.x,
              y:m.y-scrollInicatorOrigin.y,
              // height:70,
              // borderColor:'rgba(255,255,255,1)'
            }
            $('body').addClass('scroll-indicator-hover')
            if(scrollIndicatorTween.paused()){
              scrollIndicatorTween.play(0);
            }

          }
      
          gsap.to('.scroll-indicator-inner',{
            x:dest.x,
            y:dest.y,
            // height:dest.height,
            // borderColor:dest.borderColor,
            duration:2,
            ease:Power3.easeOut
          });

        })
      }, 100);


      gsap.set('.arrow',{y:300});
    }else{
      inView('.mission h2').once('enter', ()=>{
        this.missionTl.play(0);
      });
      inView('.contact h3').once('enter', ()=>{
        this.contactTl.play(0);
      });
    }

  }
  handleIntroTransitionStart(cb){

    const tl = new gsap.timeline();
    $(`.logo .line`).each((i,v)=>{
      tl.fromTo(v,{y:100},{y:0, ease:Expo.easeOut, duration: 0.2+i*0.5}, 0.4+ (i*0.015));
    });
    $(`.hero h1 .word-inner`).each((i,v)=>{
      tl.fromTo(v,{y:100},{y:0, ease:Expo.easeOut, duration: 0.2+i*0.25}, 0.4+ (i*0.015));
    });
    tl.to('.arrow',{y:0, ease:Expo.easeOut, duration:1.55},0.55);
    tl.play();

  }
 render(){
    return (
      <div className={["App",Utils.isMobile()?'is-mobile':''].join(' ')}>
        <div className="scroll-progress">
          <div className="bar"></div>
        </div>
        <div id="js-scroll">
          <Intro onTransitionStart={this.handleIntroTransitionStart}/>
          <div className="hero">
          <div className="bg" data-scroll data-scroll-speed="5" data-scroll-position="top"></div>
          <header>
            <a href="/" className="logo" >
                <div className="line">adam</div>
                <div className="line">.co</div>
                <div className="line">_</div>
            </a>
          </header>
          <div className="scroll-indicator">
            <div className="scroll-indicator-inner">
              <div className="outline">
               <div className="arrow"><div className="icon">↓</div></div>
              </div>
            </div>

            
            <div className="origin"></div>
          </div>
          
          <div className="container">
            <h1>
                My name is Adam Coulombe.<br/> I design &amp; create digital products for brands that care deeply about experience. 
              </h1>
              
          </div>
          </div>
          <div className="mission section">
          <div className="bg" data-scroll data-scroll-speed="5" data-scroll-position="top"></div>
            <div className="container">
              <h2 data-scroll data-scroll-call="mission-h2">I like making <br/> great ideas happen.</h2>
              <p data-scroll data-scroll-delay="0.3"  data-scroll-speed="1.3">
              Design and execution can make or break ideas. I sweat over small details and I am passionate about creating things that make big impressions.
              </p>
            </div>

          </div>
          <div className="contact section">
            <div className="container">
                <h3 data-scroll data-scroll-call="contact-h3">Want to get a hold of me?</h3>
                <p data-scroll data-scroll-delay="0.3"  data-scroll-speed="1.3">
                I’m on <a href="https://dribbble.com/adamcoulombe">dribbble</a>, <a href="https://github.com/adamcoulombe">github</a>, and occasionally <a href="https://twitter.com/adamcoulombe">twitter</a>, and I’d be happy to chat if you send me an <a href="mailto:me@adam.co">email</a>.
                </p>
              </div>
          </div>
          <footer>
            
              <a href="/" className="footer-logo">
                  <div className="line">adam</div>
                  <div className="line">.co</div>
                  <div className="line">_</div>
                </a>
   
            <p>
              This site is written in VSCode using ES6/React, SASS &amp; GSAP. It is typeset using Bandeins Sans.
            </p>

          </footer>
        </div>
      </div>
    );
 }
}

export default App;
